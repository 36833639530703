import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import ContentsList from '../../../components/ContentsList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="イラストレーション - スタイル" mdxType="SEO" />
    <PageTitle title="イラストレーション" enTitle="Illustration" mdxType="PageTitle" />
    <p>{`Ameba Illustration Systemは誰でもAmebaらしいイラストが作成できるように構造がシステム化されています。`}</p>
    <p>{`Amebaらしさ原則にもある「心を動かそう」「快く迎え入れよう」を意識して、デザインのアクセントや、ユーザーフレンドリーな印象を与えるために活用しましょう。`}</p>
    {
      /* textlint-disable @openameba/ameba/no-synonyms */
    }
    <ContentsList list={[{
      url: "/styles/illustration/introduction/",
      title: "イントロダクション",
      enTitle: "Introduction",
      summary: "誰でもAmebaらしいイラストが作成できるように構造がシステム化されています。"
    }, {
      url: "/styles/illustration/regulation/",
      title: "レギュレーション",
      enTitle: "Regulation",
      summary: "誰でも正しく活用できるようにレギュレーションを用意しています。"
    }]} mdxType="ContentsList"></ContentsList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      